import React, { useState } from "react";
import { getData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
    
// components
import TimesheetListTable from "../../../components/Tables/TimesheetListTable";

export default function TimesheetList() {
    const [open, setOpen] = React.useState(true);
    const [totalTimesheets, setTotalTimesheets] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    
    const ticketFilter = searchParams.get('filter');
    //console.log(ticketFilter);
    const toggleDrawer = () => {
      setOpen(!open);
    };
  
    const formatDate = (dateString) => {
      return moment(dateString).format('DD/MM/YYYY');
    };
  
    const filterTimesheetData = (timesheets_data) => {
      //console.log(tickets_data);
      let openTicket = [];
      let closeTicket = [];
      let totaltimesheet = [];
      if(timesheets_data){
        for (let i = 0; i < timesheets_data.length; i++) {
            totaltimesheet.push(timesheets_data[i])
        }
      }
     
      setTotalTimesheets(totaltimesheet);
      return ({ "total": totalTimesheets })
    }
    React.useEffect(() => {
      async function fetchData() {
        let resData = await getData('/api/employee/admins/?is_admin=1')
        let timesheets_data = resData['data']['timesheets']
        console.log(timesheets_data);
        let timesheetsData = await filterTimesheetData(timesheets_data)
  
       
        setLoading(false);
      }
      fetchData();
  
  
    }, [])
    return (
        <>
            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Timesheet</h6>
                            <div className="w-full lg:w-3/12 px-4">
                            <div className="relative w- mb-3">
                                <DatePicker
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => setDateRange(update)}
                                        isClearable
                                        placeholderText="Select Date Range"
                                        className="w-full lg:w-3/12 mb-3 px-3 py-2 border rounded"
                                    />
                                </div>
                            </div>
                            <Link to="/admin/timesheetForm">
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button">
                             Add
                            </button>
                            </Link>
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (<>
                        <div
                            className={
                                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                            }
                        >

                            <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                {/* Projects table */}
                                <TimesheetListTable totalTimesheets={totalTimesheets}></TimesheetListTable>

                            </div>
                        </div>
                    </>)}
                </div>
            </main>

        </>
    );
}
