/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "../Dropdowns/NotificationDropdown";
import UserDropdown from "../Dropdowns/UserDropdown";
import logo from '../../assets/img/logo.png';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [isSuperadmin, setSuperadmin] = React.useState(JSON.parse(localStorage.getItem('companyAdmin')));

  const [ticketsOpen, setTicketsOpen] = React.useState(false);
  const [clientsOpen, setClientsOpen] = React.useState(false);
  const [reportsOpen, setReportsOpen] = React.useState(false);
  const [inventoryOpen, setInventoryOpen] = React.useState(false);
  const [myboardOpen, setMyboardOpen] = React.useState(false);
  const [companyOpen, setCompanyOpen] = React.useState(false);
  const [entityOpen, setEntityOpen] = React.useState(false);

  const handleOpen = (section) => {
    switch (section) {
      case 'tickets':
        setTicketsOpen(!ticketsOpen);
        break;
      case 'clients':
        setClientsOpen(!clientsOpen);
        break;
      case 'company':
        setCompanyOpen(!companyOpen);
        break;  
      case 'reports':
        setReportsOpen(!reportsOpen);
        break;
      case 'inventory':
        setInventoryOpen(!inventoryOpen);
        break;
      case 'entity':
        setEntityOpen(!entityOpen);
        break;  
      case 'my board':
        setMyboardOpen(!myboardOpen);  
      default:
        break;
    }
  };

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/admin/dashboard"
          >
            <img src={logo} width='120px'  ></img>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >

                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              {/* Divider */}
              <hr className="my-4 md:min-w-full" />
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                <button
                  className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                  onClick={() => handleOpen('my board')}>
                  <i className={`fas ${myboardOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                  My Board
                </button>
              </h6>
              <ul className={`pl-4 ${myboardOpen ? 'block' : 'hidden'}`}>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/timesheetList") !== -1
                    ? "text-blueGray-700 hover:text-blueGray-500"
                    : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/timesheetList"
                >
                  <i
                    className={
                      "fas fa-clock mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/timesheetList") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Timesheet
                </Link>
              </li>
              <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "text-blueGray-700 hover:text-blueGray-500"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/attendance"
                  >
                    <i
                      className={
                        "fas fa-calendar-alt mr-3 text-sm " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Attendance
                  </Link>
              </li>
              <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "text-blueGray-700 hover:text-blueGray-500"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/profile"
                  >
                    <i
                      className={
                        "fas fa-user-circle mr-3 text-sm " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Profile
                  </Link>
                </li>
              </ul>
              {/* Divider */}
              <hr className="my-4 md:min-w-full" />
              {/* Heading */}
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                <button
                  className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                  onClick={() => handleOpen('tickets')}>
                  <i className={`fas ${ticketsOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                  Tickets
                </button>
              </h6>
              {/* Navigation */}
              <ul className={`pl-4 ${ticketsOpen ? 'block' : 'hidden'}`}>
                <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/tasks") !== -1
                        ? "text-lightBlue-500 hover:text-lightBlue-600"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/boards"
                  >
                    <i
                      className={
                        "fas fa-tasks mr-3 text-sm " +
                        (window.location.href.indexOf("/admin/tasks") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Monthly Tickets
                  </Link>
                </li>

                <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "text-blueGray-700 hover:text-blueGray-500"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/admin/tickets"
                  >
                    <i
                      className={
                        "fas fa-envelope mr-3 text-sm " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Tickets
                  </Link>
                </li>
              </ul>
              
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-3 no-underline">
                  <button
                    className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                    onClick={() => handleOpen('clients')}
                  >
                    <i className={`fas ${clientsOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                    Clients
                  </button>
                </h6>

                {/* Navigation */}
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4 pt-2">
                  <ul className={`pl-4 ${clientsOpen ? 'block' : 'hidden'}`}>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/entities"
                      >
                        <i
                          className={
                            "fas fa-building mr-3 ml-1 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Entities
                      </Link>
                    </li>
                    {isSuperadmin ? (<>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/admins"
                      >
                        <i
                          className={
                            "fas fa-user-shield mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Admins
                      </Link>
                    </li>

                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/users"
                      >
                        <i
                          className={
                            "fas fa-user-friends mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Users
                      </Link>
                    </li>
                    </>) : ''}
                  </ul>
                </ul>
              </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  <button
                    className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                    onClick={() => handleOpen('company')}>
                    <i className={`fas ${companyOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                    Company
                  </button>
                </h6>
                {/* Navigation */}
                <ul className={`pl-4 ${companyOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/company/reimbursementList"
                    >
                      <i
                        className={
                          "fas fa-money-bill-alt mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Reimbursements
                    </Link>
                  </li>
                </ul>
                {isSuperadmin ? (<>
                {/* Navigation */}
                <ul className={`pl-4 ${companyOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/company/tallyList"
                    >
                      <i
                        className={
                          "fas fa-calculator mr-3 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Tally Data
                    </Link>
                  </li>
                </ul>
                {/* Navigation */}
                <ul className={`pl-4 ${companyOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/company/incidentList"
                    >
                      <i
                        className={
                          "fas fa-exclamation-triangle mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Incident Report
                    </Link>
                  </li>
                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-3 no-underline">
                  <button
                    className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                    onClick={() => handleOpen('reports')}  >
                    <i className={`fas ${reportsOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                    Reports
                  </button>
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4 pt-2">
                  <ul className={`pl-4 ${reportsOpen ? 'block' : 'hidden'}`}>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/adminReport"
                      >
                        <i
                          className={
                            "fas fa-user-cog mr-2 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Admin Report
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/companyReport"
                      >
                        <i
                          className={
                            "fas fa-building mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Company Report
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/farReport"
                      >
                        <i
                          className={
                            "fas fa-chart-line mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        FAR Report
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/warrantyReport"
                      >
                        <i
                          className={
                            "fas fa-file-contract mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Warranty Report
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/assignmentReport"
                      >
                        <i
                          className={
                            "fas fa-tasks mr-2 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Assignment Report
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/reports/subscriptionReport"
                      >
                        <i
                          className={
                            "fas fa-file-invoice mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Subscription Report
                      </Link>
                    </li>
                  </ul>
                </ul>

                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-3 no-underline">
                  <button
                    className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                    onClick={() => handleOpen('entity')}>
                    <i className={`fas ${entityOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                    Entity Products
                  </button>
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4 pt-2">
                  <ul className={`pl-4 ${entityOpen ? 'block' : 'hidden'}`}>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/inventory/products"
                      >
                        <i
                          className={
                            "fas fa-box-open mr-2 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Products
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/inventory/assignments"
                      >
                        <i
                          className={
                            "fas fa-exchange-alt mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Product Assignment
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/inventory/warranties"
                      >
                        <i
                          className={
                            "fas fa-shield-alt mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Product Warranties
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                        }
                        to="/admin/inventory/subscriptions"
                      >
                        <i
                          className={
                            "fas fa-file-invoice mr-3 text-sm " +
                            (window.location.href.indexOf("/admin/settings") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        Subscription
                      </Link>
                    </li>
                  </ul>
                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  <button
                    className="text-xs pl-3 uppercase font-bold block focus:outline-none"
                    onClick={() => handleOpen('inventory')}>
                    <i className={`fas ${inventoryOpen ? 'fa-angle-down' : 'fa-angle-right'} mr-2`}></i>{" "}
                    Inventory
                  </button>
                </h6>
                {/* Navigation */}
                <ul className={`pl-4 ${inventoryOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/inventories/productsList"
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Product
                    </Link>
                  </li>
                </ul>
                {/* Navigation */}
                <ul className={`pl-4 ${inventoryOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/inventories/saleList"
                    >
                      <i
                        className={
                          "fas fa-tag mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Sale
                    </Link>
                  </li>
                </ul>
                {/* Navigation */}
                <ul className={`pl-4 ${inventoryOpen ? 'block' : 'hidden'}`}>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/settings") !== -1
                          ? "text-blueGray-700 hover:text-blueGray-500"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/inventories/stockList"
                    >
                      <i
                        className={
                          "fas fa-cubes mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/settings") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Stock
                    </Link>
                  </li>
                </ul>
              </>) : ''}

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}

          </div>
        </div>
      </nav>
    </>
  );
}
