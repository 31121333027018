import React, { useState } from "react";

export default function CardTicketOverview({ monthlyData }) {
  const [showFirstSevenMonths, setShowFirstSevenMonths] = useState(true);

  const totalTickets = monthlyData.reduce((total, month) => total + month.total, 0);

  const months = [
    { name: "April", value: 4, color: "#FFD700", lightColor: "#FFF8DC" }, // Gold
    { name: "May", value: 5, color: "#8A2BE2", lightColor: "#E6E6FA" }, // Blue Violet
    { name: "June", value: 6, color: "#87CEEB", lightColor: "#BFEFFF" }, // Sky Blue
    { name: "July", value: 7, color: "#FF4500", lightColor: "#FFA07A" }, // Orange Red
    { name: "August", value: 8, color: "#00FF7F", lightColor: "#98FB98" }, // Spring Green
    { name: "September", value: 9, color: "#1E90FF", lightColor: "#B0E2FF" }, // Dodger Blue
    { name: "October", value: 10, color: "#00CED1", lightColor: "#AEEEEE" }, // Dark Turquoise
    { name: "November", value: 11, color: "#ADFF2F", lightColor: "#F0FF91" }, // Green Yellow
    { name: "December", value: 12, color: "#8B008B", lightColor: "#DDA0DD" }, // Dark Magenta
    { name: "January", value: 1, color: "#FFA07A", lightColor: "#FFDAB9" }, // Light Salmon
    { name: "February", value: 2, color: "#FF1493", lightColor: "#FFB6C1" }, // Deep Pink
    { name: "March", value: 3, color: "#32CD32", lightColor: "#A9FF70" }, // Lime Green
  ];

  const renderData = () => {
    const renderMonths = showFirstSevenMonths ? months.slice(0, 7) : months;
    return renderMonths.map((month) => {
      const monthData = monthlyData.find((item) => item.m === month.value);
      const count = monthData ? monthData.total : 0;
      const percentage = totalTickets !== 0 ? ((count / totalTickets) * 100).toFixed(1) + "%" : "0%";

      return (
        <tr key={month.value}>
          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
            {month.name}
          </th>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{ width: "25%" }}>
            {count}
          </td>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{ width: "35%" }}>
            <div className="flex items-center">
              <span className="mr-2">{percentage}</span>
              <div className="relative w-full rounded overflow-hidden h-2">
                <div
                  style={{ width: percentage, backgroundColor: month.color }}
                  className="absolute top-0 left-0 h-full"
                ></div>
                <div
                  style={{ width: `calc(100% - ${percentage})`, backgroundColor: month.lightColor }}
                  className="absolute top-0 right-0 h-full"
                ></div>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h2 className="text-blueGray-700 text-xl pl-4 font-semibold">
                Ticket Statistics
              </h2>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              style={{ minWidth: "100px" }}
              onClick={() => setShowFirstSevenMonths(!showFirstSevenMonths)}>
              {showFirstSevenMonths ? "See More" : "See Less"}
            </button>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Ticket table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Month
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style={{ width: "25%" }}>
                  Count
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" style={{ width: "35%" }}>
                  [ Tickets per month / Financial Year ]  %
                </th>
              </tr>
            </thead>
            <tbody>{renderData()}</tbody>
          </table>
        </div>
      </div>
    </>
  );
}
