import React, { useState, useEffect } from "react";
import { getData } from "../../../../serverRequest.js";
import LoadingSpinner from "../../../../components/Loadder/index.js"; 
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "../DatePicker.css";

// components
import SaleListTable from "../../../../components/Tables/SaleListTable.js";

export default function SaleList() {
    const [totalSales, setTotalSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const searchParams = new URLSearchParams(window.location.search);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        async function fetchData() {
            try {
                let resData = await getData('/api/sales'); 
                let salesData = resData['data']['sales']; 
                console.log(salesData);
                let filteredSales = await filterSalesData(salesData); 

                setLoading(false);
            } catch (error) {
                console.error('Error fetching sales data:', error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const filterSalesData = (sales_data) => {
        // Filter or process your sales data here as needed
        return sales_data; // For now, returning the data as-is
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    return (
        <>
            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Sale</h6>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w- mb-3">
                                    <DatePicker
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => setDateRange(update)}
                                            isClearable
                                            placeholderText="Select Date Range"
                                            className="w-full mb-3 px-3 py-2 border rounded"
                                    />
                                </div>        
                            </div>
                            <Link to="/admin/inventories/saleForm">
                                <button
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button">
                                    Add
                                </button>
                            </Link>
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <div
                                className={
                                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                    ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                                }
                            >
                                <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                    {/* Render SaleListTable with totalSales */}
                                    <SaleListTable totalSales={totalSales}></SaleListTable>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </main>
        </>
    );
}
