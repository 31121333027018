import React from 'react';
import moment from 'moment';
import 'jquery/dist/jquery.min.js';
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { postData } from '../../serverRequest';
import LoadingSpinner from '../Loadder';

const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
};

const statusMap = {
    1: 'New',
    2: 'HR Approved',
    3: 'Manager Approved',
    4: 'Account Approved',
    5: 'Rejected'
};

const getStatusColor = (status) => {
    console.log(status);
    switch (status) {
        case 1: return { color: '#FFA500', backgroundColor: '#FFF9CC' }; // New
        case 2: return { color: '#5BC0DE', backgroundColor: '#E0F2FE' }; // HR Approved
        case 3: return { color: '#00FF00', backgroundColor: '#BDF3D8' }; // superAdmin Approved
        case 4: return { color: '#00FF00', backgroundColor: '#BDF3D8' }; // Final Approved
        case 5: return { color: '#FF0000', backgroundColor: '#FADBD8' }; // Rejected
        default: return { color: '#D3D3D3', backgroundColor: '#FFFFFF' }; // Default
    }
};

class ReimbursementListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.initializeDataTable();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.loading && prevState.loading) {
            this.initializeDataTable();
        }
    }

    componentWillUnmount() {
        this.destroyDataTable();
    }

    initializeDataTable() {
        const dateHeader = $('#reimbursements').find("th:contains('Date')")[0];
        if (dateHeader) {
            const sort_col = dateHeader.cellIndex;
            $('#reimbursements').DataTable({
                order: [[sort_col, 'desc']],
                columnDefs: [
                    {
                        targets: [],
                    }
                ]
            });
        } else {
            console.error("Date column header not found");
        }
    }

    destroyDataTable() {
        if ($.fn.DataTable.isDataTable('#reimbursements')) {
            $('#reimbursements').DataTable().destroy(true);
        }
    }

    handleApprove = async (index) => {
        const { reimbursements, onStatusChange } = this.props;
        const currentStatus = reimbursements[index].status;
        const reimbursementId = reimbursements[index].id;
        const newStatus = currentStatus + 1;

        this.setState({ loading: true });

        let reqData = {
            'id': reimbursementId,
            'status': newStatus,
        };

        try {
            let resData = await postData('/api/reimbursement/', reqData);
            console.log(resData);
            if (resData['data']['code'] === 200) {
                alert("Reimbursement Approved Successfully");
                onStatusChange(index, newStatus);
            } else {
                alert("Failed to approve reimbursement");
            }
        } catch (error) {
            console.error("Error approving reimbursement:", error);
            alert("An error occurred while approving reimbursement");
        } finally {
            this.setState({ loading: false });
        }
    };

    handleReject = (index) => {
        const { reimbursements, onStatusChange } = this.props;
        const currentStatus = reimbursements[index].status;

        if (currentStatus === 0) {
            onStatusChange(index, 5);
        }
        else if (currentStatus === 1) {
            onStatusChange(index, 5);
        }
        else if ((currentStatus === 2 || currentStatus === 3)) {
            onStatusChange(index, 5);
        }
    };

    render() {
        
        
        return (
            <div className=''>
                {this.state.loading ? 
                    <LoadingSpinner />
                 : 
                 <div className="overflow-x-auto">
                <table id="reimbursements" className="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Claim ID
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Date
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Applied By
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Reimbursement Amount
                            </th>
                            {/* <th
                    className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        ("light" === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                    >
                    Reimbursement Type
                    </th> */}
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Nature of Expense
                            </th>
                            {/* <th
                    className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        ("light" === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                    >
                    Description
                    </th> */}
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }>
                                Location
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    ("light" === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }>
                                Status
                            </th>
                            {this.props.isActionEnable ?
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        ("light" === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }>
                                    Action
                                </th> : ''}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.reimbursements.map((item, index) => (
                            <tr key={index}>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <a style={{ "light": '#0074cd' }} href={'/admin/company/reimbursement-data/?reimbursement_id=' + item.id}>{item.claim_id}</a>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {formatDate(item.date)}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {item.employee}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {item.amount}
                                </td>
                                {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.category_id}
                    </td> */}
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {item.nature}
                                </td>
                                {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.description}
                    </td> */}
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {item.location}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                    style={getStatusColor(item.status)}>
                                    <i className="fas fa-circle mr-2" style={{ color: getStatusColor(item.status).color }}></i>
                                    <span style={{ color: '#000000' }}>{statusMap[item.status]}</span>
                                </td>
                                {this.props.isActionEnable ?
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                                        <>
                                            {console.log(this.props.isHrLogin)}
                                            {((this.props.isHrLogin && item.status === 1) ||
                                                (this.props.isManagerLogin && item.status === 2) ||
                                                (this.props.isAccountLogin && item.status === 3)) ?
                                                <>
                                                    <button
                                                        className="text-white px-4 py-1 rounded mr-2"
                                                        style={{ backgroundColor: 'rgba(34, 197, 94, 0.8)' }}
                                                        onClick={() => this.handleApprove(index)}
                                                        title="Approve">
                                                        <i className="fas fa-check"></i>
                                                    </button>
                                                    <button
                                                        className="text-white px-4 py-1 rounded"
                                                        style={{ backgroundColor: 'rgba(239, 68, 68, 0.8)' }}
                                                        onClick={() => this.handleReject(index)}
                                                        title="Reject">
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </> : ''}
                                        </>

                                    </td> : ''}
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                }
        </div>);
    }
}

export default ReimbursementListTable;
