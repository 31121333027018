import React, { useState, useEffect } from "react";
import { getData } from "../../../../serverRequest";
import LoadingSpinner from "../../../../components/Loadder";
import moment from 'moment';
import { Link } from 'react-router-dom';

// components
import ReimbursementListTable from "../../../../components/Tables/ReimbursementListTable";

export default function ReimbursementList() {
    const [open, setOpen] = useState(true);
    const [reimbursements, setReimbursements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuperadmin, setSuperadmin] = React.useState(JSON.parse(localStorage.getItem('companyAdmin')));
    const searchParams = new URLSearchParams(window.location.search);
    const [isActionEnable, setActionEnable] = useState(false);
    const [isHr, setHrLogin] = useState(false);
    const [isManagerLogin, setManagerLogin] = useState(false);
    const [isAccountLogin, setAccountLogin] = useState(false);

    const ticketFilter = searchParams.get('filter');
    // console.log(ticketFilter);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    const filterReimbursementData = (reimbursements_data) => {
        let totalReimbursements = [];
        if (reimbursements_data) {
            for (let i = 0; i < reimbursements_data.length; i++) {
                totalReimbursements.push(reimbursements_data[i]);
            }
        }

        setReimbursements(totalReimbursements);
        return { "total": totalReimbursements };
    }

    const handleStatusChange = (index, newStatus) => {
        const updatedReimbursements = [...reimbursements];
        updatedReimbursements[index].status = newStatus; 
        setReimbursements(updatedReimbursements); 
    };

    useEffect(() => {
        async function fetchData() {
            let resData = await getData('/api/reimbursement/');
            console.log(resData);
            let reimbursements_data = resData['data']['rmb_claim_data'];
            if(resData['data']['is_hr'] || isSuperadmin){
                
                setActionEnable(true);
            }
            if(resData['data']['is_hr']){
                setHrLogin(true);
            }
            if(resData['data']['is_manager']){
                setManagerLogin(true);
            }
            if(resData['data']['is_account']){
                setAccountLogin(true);
            }
            console.log(reimbursements_data);
            let reimbursementData = await filterReimbursementData(reimbursements_data);

            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <>
            <main>
                <div className="relative block py-24 lg:pt-0 px-4">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Reimbursements</h6>
                            
                                <Link to="/admin/company/reimbursementForm">
                                    <button
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button">
                                        Add
                                    </button>
                                </Link>
                            
                        </div>
                    </div>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <div
                                className={
                                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                                    ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                                }
                            >
                                <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4">
                                    {/* Projects table */}
                                    <ReimbursementListTable reimbursements={reimbursements} isHrLogin={isHr} isAccountLogin={isAccountLogin} isManagerLogin={isManagerLogin} isActionEnable={isActionEnable} onStatusChange={handleStatusChange} />
                                </div>
                            </div>
                        </>)}
                </div>
            </main>
        </>
    );
}
