import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

const statusMap = {
  1: 'New',
  2: 'HR Approved',
  3: 'Manager Approved',
  4: 'Account Approved',
  5: 'Rejected'
};


export default function ReimbursementData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const reimbursementId = searchParams.get('reimbursement_id');
  const [showModal, setShowModal] = useState(false);
  const [reimbursementDate, setReimbursementDate] = useState();
  const [reimbursementType, setReimbursementType] = useState();
  const [natureOfExpense, setNatureOfExpense] = useState();
  const [reimbursementAmount, setReimbursementAmount] = useState();
  const [location, setLocation] = useState(); 
  const [claimId, setClaimId] = useState(); 
  const [employee, setEmployee] = useState(); 
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [requester, setRequester] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  
  const handleSubmit = async (event) => {
    // Prevent page reload
    setLoading(true);
    event.preventDefault();
    let { reimbursement_date, reimbursement_type, nature_of_expense, reimbursement_amount, location, status, description } = document.forms[0];
    let reqData = {
      'reimbursement_id': reimbursementId,
      'reimbursement_date': reimbursement_date ? reimbursement_date.value : '',
      'reimbursement_type': reimbursement_type ? reimbursement_type.value : '',
      'nature_of_expense': nature_of_expense ? nature_of_expense.value : '',
      'reimbursement_amount': reimbursement_amount ? reimbursement_amount.value : '',
      'location': location ? location.value : '',
      'status': status ? status.value : '',
      'description': description ? description.value : '',
    }

    let resData = await postData('/api/reimbursement/', reqData);
    if (resData['data']['code'] === 200) {
      setLoading(false);
      setShowModal(true);
      window.location.href = '/admin/reimbursements';
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/reimbursement?claim_id=' + reimbursementId);
      let reimbursement_data = resData['data']['rmb_claim_data'];
      console.log(resData);
      if(reimbursement_data){
        setReimbursementDate(reimbursement_data['date']);
        setReimbursementType(reimbursement_data['category']);
        setNatureOfExpense(reimbursement_data['nature']);
        setReimbursementAmount(reimbursement_data['amount']);
        setLocation(reimbursement_data['location']);
        setStatus(reimbursement_data['status']);
        setDescription(reimbursement_data['description']);
        setClaimId(reimbursement_data['claim_id'].toUpperCase());
        setEmployee(reimbursement_data['employee']);
        let loggedInUser = localStorage.getItem('email');
        console.log(loggedInUser);
        if(loggedInUser === reimbursement_data['created_by']){
          setRequester(true);
        }
      }
     
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="reimbursement-details-container p-4 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">{employee} ({claimId})</h6>
                {requester?(
                <button className="cursor-pointer opacity-30  px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" onClick={() => setIsEditable(isEditable?false:true)}>
                        <i className="fas fa-edit text-xl text-blueGray-500 hover:outline-none focus:outline-none"></i>
                </button>
                ):''} 
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursement_date">Reimbursement Date</label>
                  <input type="date"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="reimbursement_date" defaultValue={reimbursementDate} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursement_type">Reimbursement Type</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="reimbursement_type" defaultValue={reimbursementType} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="nature_of_expense">Nature of Expense</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="nature_of_expense" defaultValue={natureOfExpense} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="reimbursement_amount">Reimbursement Amount</label>
                  <input type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="reimbursement_amount" defaultValue={reimbursementAmount} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="location">Location</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="location" defaultValue={location} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="status">Status</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled
                    name="status" defaultValue={statusMap[status]} />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="description">Description</label>
                  <textarea
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="description" defaultValue={description} />
                </div>
              </div>
            </div>
            {isEditable && (
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                type="Submit"
                value="Submit" />
            </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
